














import {
  Component,
  Vue
} from "vue-property-decorator";
import {
  api
} from '@/api'
import {
  WechatModule
} from '@/store/modules/wechat'
import search from '@/components/global/search.vue'
import DialogueItem from '@/components/friend/dialogue-item.vue'
import GlobalRobotList from '@/components/global/robot-list.vue'
import GlobalFooter from '@/components/global/footer.vue'
import {
  MsgType
} from '@/utils/interface'
import {
  msgTyepVar
} from '@/utils/config'
import {
  BroadcastMsgContentType
} from '@/utils/interface'
import bus from '@/utils/bus'
import {
  onScroll
} from '@/utils/func'

@Component({
  name: 'dialogue',
  components: {
    search,
    DialogueItem,
    GlobalRobotList,
    GlobalFooter
  }
})
export default class Dialogue extends Vue {
  private hasNextPage: boolean = true
  private sessionPageNo: number = 1
  private sessionPageSize: number = 15
  private sessionList: any = []
  private statusColor: any = {
    1: 'off-line',
    2: 'on-line',
    3: 'def-line'
  }
  private isRequest = false

  // 当前选择的设备wxid
  private get wxId(): number {
    return WechatModule.wxId
  }

  // 当前选择的设备字符串wxid
  private get wxStrId(): string {
    return WechatModule.wxStrId
  }

  // 获取选择的设备信息
  private get deviceInfo(): any {
    return WechatModule.currentDeviceInfo ? JSON.parse(WechatModule.currentDeviceInfo) : ''
  }

  // 当前选择的好友wxid
  private get friendId(): number {
    return WechatModule.friendId
  }

  // 当前选择的好友字符串id
  private get friendStrId(): string {
    return WechatModule.friendStrId
  }

  private mounted() {
    bus.$on('Boardcast', this.getBoardCastData)
  }

  private beforeDestroy() {
    bus.$off('Boardcast')
  }

  /**
   * @func 初始化会话列表
   * @param global-robot-list 的回调
   */
  private initSessionList(obj: any) {
    this.hasNextPage = true
    this.isRequest = false
    this.sessionPageNo = 1
    this.sessionList = []
    this.getSessionList(obj.wxid)
  }

  /**
   * @func 获取会话列表
   * @param wxid 设备的wxid 
   */
  private getSessionList(wxid: number) {
    if (this.isRequest) return
    if (!this.hasNextPage) return
    this.isRequest = true
    const params: any = {
      wechat_id: wxid,
      page_no: this.sessionPageNo,
      page_size: this.sessionPageSize
    }
    api.getFriendForMsgList(params).then((res: any) => {
      !res.list || res.list.length < this.sessionPageSize ?
        this.hasNextPage = false :
        this.sessionPageNo++
      if (res.list) {
        res.list.forEach((cov: any) => {
          if (!cov.record.full_context) {
            cov.record.full_context = this.getFullContext(cov.record.msg_type, cov.record)
          }
          cov.newMsgNum = 0
          this.sessionList.push(cov)
        })
      }
      this.isRequest = false
    }).catch((err: any) => {
      this.isRequest = false
    })
  }

  private getFullContext(type: number, val: any) {
    let username;
    if (val.from_username === this.deviceInfo.wechat) {
      username = this.deviceInfo.remark || this.deviceInfo.name
    } else {
      username = val.remark || val.nick_name || val.chatroom_member_remark || val.chatroom_member_nick;
    }
    if (type === MsgType.msg_type_text) {
      return (val.context || val.content);
    } else {
      return '[' + msgTyepVar[type] + ']';
    }
  }

  private listenScroll(e: any) {
    onScroll({
      e: e,
      scrollUp: () => {
        if (this.sessionList.length > 0) {
          this.getSessionList(this.wxId);
        }
      }
    })
  }

  /**
   * @func 监听广播
   */
  private getBoardCastData(data: any) {
    const msg: any = JSON.parse(data.msg.content)

    if (data.msg.type === BroadcastMsgContentType.msg_type) {
      this.updateSession(data)
      return
    }
  }

  /**
   * @func 会话更新
   * @param msg 消息内容
   */
  private updateSession(data: any) {
    const msg = JSON.parse(data.msg.content);
    let isNew: boolean = true

    if (data.msg && (msg.wxid !== this.wxId)) { // 判断消息是否为当前设备
      return false
    }

    if (this.sessionList.length) {
      this.sessionList.forEach((wechat: any, index: number, arr: any[]) => {
        if (wechat.chat_room && wechat.chat_room.username === msg.chatroom_id) {
          wechat.record.unread_num += 1
          wechat.record.full_context = this.getFullContext(msg.msg_type, msg)
          wechat.record.record_time = msg.record_time
          this.sessionList.splice(index, 1)
          this.sessionList.unshift(wechat)
          isNew = false
        }
        if (((wechat.contact.wx_src_id === msg.from_username) && (this.wxStrId === msg.to_username)) ||
          ((wechat.contact.wx_src_id === msg.to_username) && (this.wxStrId === msg.from_username))) {
          wechat.record.unread_num++;
          wechat.record.full_context = this.getFullContext(msg.msg_type, msg);
          wechat.record.record_time = msg.record_time;
          this.sessionList.splice(index, 1);
          this.sessionList.unshift(wechat);
          isNew = false;
        }
      })
    }

    if (isNew && msg) {
      const newMsg: any = {
        chat_type: 0,
        contact: {
          city: msg.city,
          head_url: msg.head_url,
          id: msg.id,
          nick_name: msg.nick_name,
          province: msg.province,
          remark: msg.remark,
          sex: msg.sex,
          status: msg.status,
          wechat_no: msg.wechat_no,
          wx_src_id: msg.wx_src_id
        },
        record: {
          context: msg.content,
          from_username: msg.from_username,
          to_username: msg.to_username,
          head_url: msg.head_url,
          id: 0,
          msg_type: msg.msg_type,
          nick_name: msg.nick_name,
          record_time: msg.record_time,
          full_context: this.getFullContext(msg.msg_type, msg),
          unread_num: 1,
        }
      }
      if (msg.chatroom_id) {
        newMsg.chat_room = {
          id: msg.chat_id,
          wechat_id: this.wxId,
          member_count: 0,
          chatroom_nick: msg.chatroom_member_nick,
          chatroom_icon: msg.chatroom_member_icon,
          username: msg.chatroom_id,
        }
      }

      this.sessionList.unshift(newMsg);
    }
  }

  /**
   * @func 搜索
   */
  private toSearch() {
    this.$router.push({
      name: 'Search'
    })
  }
}
