






















  import {
    Component,
    Prop,
    Vue
  } from "vue-property-decorator"
  import {
    getTime
  } from '@/utils/func'
  import {
    WechatModule
  } from "@/store/modules/wechat"
  import bus from '@/utils/bus'
  import {
    api
  } from '@/api'
import { ChatModule } from "@/store/modules/chat"

  @Component({
    name: 'dialogue-item',
    filters: {
      tranTime(val: any) {
        return getTime(val);
      }
    }
  })
  export default class DialogueItem extends Vue {
    @Prop({}) private data!: any
    private defChatRoomAvatar: string = 'https://oss.guojiangmedia.com/planr/chat-room-icon2.png'
    private defChatAvatar: string = 'https://oss.guojiangmedia.com/wxbackend/default-avatar.png'

    private get wxId(): number {
      return WechatModule.wxId
    }

    /**
     * @func 选择会话
     */
    private chooseFri() {
      let wx_str_id = ''
      let num = this.data.record.unread_num
      if (this.data.chat_room) {
        WechatModule.changeFriendId(this.data.chat_room.id)
        WechatModule.changeFriendStrId(this.data.chat_room.username)
        WechatModule.changeIsChatRoom(true)
        WechatModule.changeFriendInfo(JSON.stringify(this.data.chat_room))
        wx_str_id = this.data.chat_room.username
      } else {
        WechatModule.changeFriendId(this.data.contact.id)
        WechatModule.changeFriendStrId(this.data.contact.wx_src_id)
        WechatModule.changeIsChatRoom(false)
        WechatModule.changeFriendInfo(JSON.stringify(this.data.contact))
        wx_str_id = this.data.contact.wx_src_id
      }
      ChatModule.changeMsgTaskId([])
      if (num) {
        this.clearRedPoint(this.wxId, wx_str_id, num)
      }
      this.$router.push({
        name: 'Chat'
      })
    }

    /**
     * @func 消除红点
     * @param wxid 当前设备微wxid
     * @param wx_src_id 当前好友字符串id
     * @param num 未读消息数量
     */
    private async clearRedPoint(wxid: number, wx_src_id: string, num: number) {
      try {
        const res: any = api.friendTask({
          robot: wxid,
          task: {
            task_time: parseInt(new Date().getTime() / 1000 + '', 10),
            task_friends: {
              task_type: 8,
              clear_red_dot: {
                wxid: wx_src_id,
              },
            },
          }
        })
        this.data.record.unread_num -= num
      } catch (err) {
        console.log(err)
      } 
    }
  }
