

































import {
  Component,
  Vue
} from "vue-property-decorator"
import {
  api
} from "@/api"
import {
  WechatModule
} from "@/store/modules/wechat"
import bus from '@/utils/bus'
import {
  BroadcastMsgContentType
} from "@/utils/interface";
import search from '@/components/global/search.vue'
import {setComToken, getComToken} from '@/utils/auth'

@Component({
  name: 'global-robot-list',
  components: {
    search
  }
})
export default class GlobalRobotList extends Vue {
  private isOpen = false;
  private robotList: any = []
  private searchResultList: any = []
  private currentRobot: any = {}
  private isSearch: boolean = false
  private statusColor: any = {
    1: 'off-line',
    2: 'on-line',
    3: 'def-line'
  }

  private get deviceInfo(): any {
    return WechatModule.currentDeviceInfo ? JSON.parse(WechatModule.currentDeviceInfo) : null
  }

  created() {
    this.getDevices()
  }

  mounted() {
    bus.$on('Boardcast', this.getBoardCastData)
  }

  beforeDestroy() {
    bus.$off('Boardcast')
  }

  /**
   * @func 监听广播
   */
  private getBoardCastData(data: any) {
    const msg: any = JSON.parse(data.msg.content)

    if (data.msg.type === BroadcastMsgContentType.msg_type) {
      this.addRedPoint(msg)
      return
    }

    if (data.msg.type === BroadcastMsgContentType.robot_status) {
      let status = 0
      if (msg.bot_online_status.online_status) status = 2;
      else status = 1;
      this.changeWechatStatus({
        wxid: msg.wechat_id,
        status: status
      })
      return
    }
  }

  /**
   * @func 添加设备红点
   * @param msg 消息内容
   */
  private addRedPoint(msg: any) {
    for (let i = 0; i < this.robotList.length; i++) {
      let item = this.robotList[i]
      if (item.wxid === msg.wxid && item.wechat !== msg.from_username) {
        this.robotList[i].unread++
      }
    }
  }

  /**
   * @func 修改设备状态
   * @param obj 
   */
  private changeWechatStatus(obj: any) {
    for (let i = 0; i < this.robotList.length; i++) {
      let item = this.robotList[i]
      if (item.wxid === obj.wxid) {
        this.robotList[i].status = obj.status
      }
    }
  }

  /**
   * @func 搜索结果
   */
  private searchResult(query ? : string) {
    if (!query) {
      this.isSearch = false
      this.searchResultList = []
      return
    } else {
      this.isSearch = true
      this.searchResultList = []
    }

    for (let i = 0; i < this.robotList.length; i++) {
      const item = this.robotList[i]
      const reg: any = new RegExp(query || '')
      if (item.name.match(reg) || item.remark.match(reg) || item.wechat_no.match(reg)) {
        this.searchResultList.push(item)
      }
    }
  }

  private handleOpen() {
    this.isOpen = !this.isOpen
  }

  private handleClose() {
    this.isOpen = false
  }

  /**
   * @func 点击设备
   */
  private clickRobot(robot: any) {
    this.isSearch = false
    this.searchResultList = []
    this.currentRobot = robot
    WechatModule.changeDeviceId(this.currentRobot.device_id)
    WechatModule.changeDeviceInfo(JSON.stringify(this.currentRobot))
    WechatModule.changeWxId(this.currentRobot.wxid)
    WechatModule.changeWxStrId(this.currentRobot.wechat)
    // 缓存最后一次浏览的设备
    setComToken('planr-wechat-h5_deviceinfo', JSON.stringify(this.currentRobot))
    this.handleClose()
    this.$emit('changeRobot', {
      wxid: this.currentRobot.wxid,
      wx_str_id: this.currentRobot.wechat
    })
  }

  /**
   * @func 获取设备列表
   */
  private getDevices(query ? : string) {
    let params: any = {}
    if (query) {
      this.isSearch = true
      params.keyword = query
    } else {
      this.isSearch = false
    }
    api.getGroupDevice(params).then((res: any) => {
      this.robotList = res.robot_list || []
      if (res.group) {
        res.group.map((item: any) => {
          if (item.robots) {
            this.robotList = this.robotList.concat(item.robots)
          }
        })
      }
      // 判断是否有缓存
      let devInfo: any = null
      if (getComToken('planr-wechat-h5_deviceinfo')) { 
        devInfo = JSON.parse(getComToken('planr-wechat-h5_deviceinfo') + '')
        WechatModule.changeDeviceId(devInfo.device_id)
        WechatModule.changeDeviceInfo(getComToken('planr-wechat-h5_deviceinfo') + '')
        WechatModule.changeWxId(devInfo.wxid)
        WechatModule.changeWxStrId(devInfo.wechat)
      }

      if (this.robotList.length && !this.deviceInfo && !devInfo) {
        this.currentRobot = res.robot_list[0]
        WechatModule.changeDeviceId(this.currentRobot.device_id)
        WechatModule.changeDeviceInfo(JSON.stringify(this.currentRobot))
        WechatModule.changeWxId(this.currentRobot.wxid)
        WechatModule.changeWxStrId(this.currentRobot.wechat)
        this.$emit('changeRobot', {
          wxid: this.currentRobot.wxid,
          wx_str_id: this.currentRobot.wechat
        })
      }

      if (this.deviceInfo || devInfo) {
        this.currentRobot = this.deviceInfo || devInfo
        this.$emit('changeRobot', {
          wxid: this.currentRobot.wxid,
          wx_str_id: this.currentRobot.wechat
        })
      }
    })
  }
}
